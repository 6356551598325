import { ElementRef, Injectable } from '@angular/core';
import { WIDGET_CONSTANTS } from '../constants/widget-constants';
import { PartnerDao } from '../db-models/widget-conf-dao';
import { CustomerProfile, GlobalObjects, Partner } from '../models/global';
import { WidgetColorConf } from '../models/widget-color.model';
import { WidgetConf } from '../models/widget-conf';
import { LoggerService } from './logger.service';
import { PartnerService } from './partner.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetUtilService {

  widgetConf: WidgetConf;
  zipCode: string;
  customerProfile: CustomerProfile;

  constructor(
    private partnerService: PartnerService
  ) {
  }

  createPartnerGlobalData(widgetConf: WidgetConf, language: string, partnerDao: PartnerDao): Partner {
    const partner = new Partner();
    partner.id = widgetConf.id;
    partner.uuid = widgetConf.uuid;
    partner.name = widgetConf.name;
    partner.bookingName = widgetConf.bookingName;
    partner.logo = widgetConf.logo;
    partner.currency = widgetConf.currency;
    partner.url = widgetConf.url;
    partner.languageCode = language;
    partner.showEgalWer = widgetConf.showEgalWer;
    partner.needCustomerAddress = widgetConf.needCustomerAddress;
    partner.show_message_on_widget = widgetConf.show_message_on_widget;
    partner.needPhoneNumber = widgetConf.needPhoneNumber;
    partner.needMobileNumber = widgetConf.needMobileNumber;
    partner.countryCode = widgetConf.countryCode;
    partner.courtesyForm = widgetConf.courtesyForm;
    partner.appointmentLeadTime = widgetConf.appointmentLeadTime;
    partner.eventLeadTime = widgetConf.eventLeadTime;
    partner.appointmentBookingInfo = widgetConf.appointmentBookingInfo;
    partner.eventSlotBookingInfo = widgetConf.eventSlotBookingInfo;
    partner.hide_login_on_bookingsite = widgetConf.hide_login_on_bookingsite;
    partner.hide_back_on_bookingsite = widgetConf.hide_back_on_bookingsite;
    partner.hide_book_additional_appointment_button = widgetConf.hide_book_additional_appointment_button;
    partner.subscription_type_id = widgetConf.subscriptionTypeId;
    partner.allow_multiple_services_bookings = widgetConf.allow_multiple_services_bookings;
    partner.hide_resource_step = Number(partnerDao.hide_resource_step);
    partner.hide_mail_resource = Number(partnerDao.hide_mail_resource);
    partner.future_booking_threshold_appointments = partnerDao.future_booking_threshold_appointments;
    partner.future_booking_threshold_events = partnerDao.future_booking_threshold_events;
    partner.widget_appointment_service_category_status = partnerDao.widget_appointment_service_category_status;
    partner.supported_widget_languages = partnerDao.supported_widget_languages;
    partner.language_identifier = partnerDao.language_identifier;
    // partner.templates = partnerDao.templates;
    partner.enable_store_postcode_feature = Number(partnerDao.enable_store_postcode_feature);
    // partner.enable_store_postcode_feature = 1;
    partner.ask_for_customer_notification_preference = partnerDao.ask_for_customer_notification_preference;
    partner.successful_booking_redirect_url = partnerDao.successful_booking_redirect_url;
    partner.redirect_after_booking_action = partnerDao.redirect_after_booking_action;
    // partner.ask_for_customer_notification_preference = 1;
    partner.disable_automatic_search = Number(partnerDao.disable_automatic_search);
    partner.enable_services_search = Number(partnerDao.enable_services_search);
    partner.widget_hide_resource_step_for_default_workers = Number(partnerDao.widget_hide_resource_step_for_default_workers);
    partner.widget_disable_search_all_resource_button = Number(partnerDao.widget_disable_search_all_resource_button);
    partner.activate_phone_number_verification_feature = Number(partnerDao.activate_phone_number_verification_feature);
    partner.activate_mobile_number_verification_feature = Number(partnerDao.activate_mobile_number_verification_feature);
    partner.widget_email_shown = Number(partnerDao.widget_email_shown);
    partner.widget_email_required = Number(partnerDao.widget_email_required);
    partner.widget_show_lead_creation_button = Number(partnerDao.widget_show_lead_creation_button);
    partner.show_distance_in_stores = Number(partnerDao.show_distance_in_stores);
    partner.automatic_store_zip_search = Number(partnerDao.automatic_store_zip_search);
    partner.show_salutation_in_widget = Number(partnerDao.show_salutation_in_widget);
    partner.internal_widget_email_shown = Number(partnerDao.internal_widget_email_shown);
    partner.internal_widget_email_required = Number(partnerDao.internal_widget_email_required);
    partner.show_inline_description = Number(partnerDao.show_inline_description);
    partner.is_maintenance_mode_enabled = Number(partnerDao.is_maintenance_mode_enabled);
    partner.show_privacy_message_checkbox = Number(partnerDao.show_privacy_message_checkbox);
    partner.is_round_robin_feature_enabled = Number(partnerDao.is_round_robin_feature_enabled);
    partner.is_widget_anyone_exception_enabled = Number(partnerDao.is_widget_anyone_exception_enabled)
    partner.widget_address_required = Number(partnerDao.widget_address_required);
    partner.is_customer_profiles_feature_enabled = Number(partnerDao.is_customer_profiles_feature_enabled);
    partner.customer_profiles = partnerDao.customer_profiles;
    partner.widget_salutation_required = Number(partnerDao.widget_salutation_required);
    partner.widget_phone_required = Number(partnerDao.widget_phone_required);
    partner.widget_message_required = Number(partnerDao.widget_message_required);
    partner.internal_widget_store_search_list = Number(partnerDao.internal_widget_store_search_list);
    partner.hide_event_capacity = Number(partnerDao.hide_event_capacity);
    partner.widget_company_name_required = Number(partnerDao.widget_company_name_required);
    partner.widget_company_name_shown = Number(partnerDao.widget_company_name_shown);
    partner.widget_internal_comment_required = Number(partnerDao.widget_internal_comment_required);
    partner.widget_internal_comment_shown = Number(partnerDao.widget_internal_comment_shown);
    partner.is_redirect_after_booking_enabled = Number(partnerDao.is_redirect_after_booking_enabled);
    partner.is_widget_banner_hint_enabled = Number(partnerDao.is_widget_banner_hint_enabled);
    partner.widget_banner_hint_color = partnerDao.widget_banner_hint_color;
    partner.widget_banner_hint_text = partnerDao.widget_banner_hint_text;
    partner.anyone_feature_hide_ressource = partnerDao.anyone_feature_hide_ressource;
    partner.widget_title_required = Number(partnerDao.widget_title_required);
    partner.widget_title_shown = Number(partnerDao.widget_title_shown);
    partner.widget_country_required = Number(partnerDao.widget_country_required);
    partner.widget_country_shown = Number(partnerDao.widget_country_shown);
    partner.widget_show_resource_store_name = Number(partnerDao.widget_show_resource_store_name);
    partner.is_group_appointment_store_feature_enabled = Number(partnerDao.is_group_appointment_store_feature_enabled);
    partner.is_group_appointment_category_feature_enabled = Number(partnerDao.is_group_appointment_category_feature_enabled);
    partner.is_widget_event_single_slot_preselect_enabled = Number(partnerDao.is_widget_event_single_slot_preselect_enabled);
    partner.widget_mobile_required = Number(partnerDao.widget_mobile_required);
    partner.widget_show_location_on_appointment_proposal = Number(partnerDao.widget_show_location_on_appointment_proposal);
    partner.is_widget_service_shortcut_button_enabled = Number(partnerDao.is_widget_service_shortcut_button_enabled);
    partner.include_city_name_in_postcode_results = Number(partnerDao.include_city_name_in_postcode_results);
    partner.widget_show_free_slots_in_overview = Number(partnerDao.widget_show_free_slots_in_overview);
    partner.is_captcha_feature_enabled = Number(partnerDao.is_captcha_feature_enabled);
    partner.show_worker_selection_on_date_step = Number(partnerDao.show_worker_selection_on_date_step);
    partner.is_widget_postcode_worker_random_selection_enabled = Number(partnerDao.is_widget_postcode_worker_random_selection_enabled);
    partner.show_widget_slot_location = Number(partnerDao.show_widget_slot_location);
    partner.widget_jumps_to_resources_step_when_single_service = Number(partnerDao.widget_jumps_to_resources_step_when_single_service);
    partner.group_appointments_filter_behaviour = partnerDao.group_appointments_filter_behaviour;
    partner.is_appointment_reservation_enabled = partnerDao.is_appointment_reservation_enabled;
    partner.split_personal_form_and_summary_in_widget = partnerDao.split_personal_form_and_summary_in_widget;
    partner.show_cross_selling_services_on_summary_step = partnerDao.show_cross_selling_services_on_summary_step;
    partner.show_widget_calendar_notice = partnerDao.show_widget_calendar_notice;

    return this.validatePartnerFeatures(partner);
  }

  validatePartnerFeatures(partner: Partner): Partner {
    partner.is_round_robin_feature_enabled = Number(this.partnerService.hasFeature('is_round_robin_enabled') && partner.is_round_robin_feature_enabled);
    LoggerService.warn('[feature] Final value of feature is_round_robin_feature_enabled', partner.is_round_robin_feature_enabled);

    partner.is_redirect_after_booking_enabled = Number(
      this.partnerService.hasFeature('is_external_success_page_forwarding_enabled') && partner.is_redirect_after_booking_enabled
    );
    LoggerService.warn(
      '[feature] Final value of feature is_external_success_page_forwarding_enabled',
      partner.is_redirect_after_booking_enabled
    );

    partner.is_widget_banner_hint_enabled = Number(
      this.partnerService.hasFeature('is_widget_hint_enabled') && partner.is_widget_banner_hint_enabled
    );
    LoggerService.warn('[feature] Final value of feature is_widget_hint_enabled', partner.is_widget_banner_hint_enabled);

    return partner;
  }

  createWidgetColorConf(partner: PartnerDao): WidgetColorConf {
    const widgetColorConf: WidgetColorConf = new WidgetColorConf();
    widgetColorConf.widget_header_background_color = partner.widget_header_background_color;
    widgetColorConf.widget_footer_background_color = partner.widget_footer_background_color;
    widgetColorConf.widget_text_color = partner.widget_text_color;
    widgetColorConf.widget_footer_button_color = partner.widget_footer_button_color;
    widgetColorConf.widget_header_active_color = partner.widget_header_active_color;
    widgetColorConf.widget_background_color = partner.widget_background_color;
    return widgetColorConf;
  }

  createGlobalObjectData(
    widgetConf: WidgetConf, workerUuid: string, eventId: string, isInternal: boolean,
    isWhitelabeled: boolean, user_browser_language: string, user_selected_language: string): GlobalObjects {

    const globals = new GlobalObjects();
    globals.isInternal = isInternal;
    globals.isWhitelabeled = isWhitelabeled;
    globals.customers = null;
    globals.eventId = null;
    globals.user_browser_language = user_browser_language;
    globals.user_selected_language = user_selected_language;

    // set event ID if given in url
    if (eventId) {
      globals.eventId = eventId;
    }

    return globals;
  }

  setWidgetConf(widgetConf: WidgetConf): void {
    this.widgetConf = widgetConf;
  }

  getWidgetConf(): WidgetConf {
    return this.widgetConf;
  }

  getBookingTypes(bookingType: string, widgetConf: WidgetConf): string[] {
    const bookTypesEnabled = [];
    switch (bookingType?.toLowerCase()) {
      case WIDGET_CONSTANTS.APPOINTMENT:
      case WIDGET_CONSTANTS.APPOINTMENTS:
        bookTypesEnabled.push(WIDGET_CONSTANTS.APPOINTMENT);
        break;

      case WIDGET_CONSTANTS.EVENT:
      case WIDGET_CONSTANTS.EVENTS:
        bookTypesEnabled.push(WIDGET_CONSTANTS.EVENT);
        break;

      case WIDGET_CONSTANTS.RESOURCE:
      case WIDGET_CONSTANTS.RESOURCES:
        bookTypesEnabled.push(WIDGET_CONSTANTS.RESOURCE);
        break;

      case WIDGET_CONSTANTS.ANY:
        // Create available bookings array
        if (widgetConf.appointmentBookingEnabled) {
          bookTypesEnabled.push(WIDGET_CONSTANTS.APPOINTMENT);
        }
        if (widgetConf.eventBookingEnabled) {
          bookTypesEnabled.push(WIDGET_CONSTANTS.EVENT);
        }
        if (widgetConf.resourceBookingEnabled) {
          bookTypesEnabled.push(WIDGET_CONSTANTS.RESOURCE);
        }
        break;
      default:
        bookTypesEnabled.push(WIDGET_CONSTANTS.APPOINTMENT);
    }
    return bookTypesEnabled;
  }

  sendMessageToParent(messageObject: { [key: string]: any }): void {
    if (window !== window.parent) {
      window.parent.postMessage(messageObject, '*');
    }
  }

  focusInvalidElements(elementRef: ElementRef<any>): void {
    // Assuming you have a reference to the element
    const focusableElements: NodeListOf<HTMLElement> = elementRef.nativeElement.querySelectorAll('input, textarea, ng-select');

    // Find the first element with the 'ng-invalid' class
    const invalidElement: HTMLElement | undefined = Array.from(focusableElements).find(element => {
      return element.classList?.value?.split(' ')?.includes('ng-invalid'); // Check if 'is-invalid' is present
    });

    // Focus the first invalid element, if found
    invalidElement?.focus();
  }
}
