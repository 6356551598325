export const fi_FI = {
  customer_type: 'Asiakastyyppi',
  manage_appointment_now: 'Hallinnoi tapaamista nyt',
  maintenance_page_title: 'Calensoa parannetaan juuri sinulle',
  maintenance_page_subtitle: 'Calensoa päivitetään uusimpaan versioon. Ole kärsivällinen ja tarkista tilanne muutaman minuutin kuluttua.',
  maintenance_page_button: 'Tilasivulle',
  load_more: 'Lataa lisää',
  show_more: 'Näytä lisää',
  internal_label: 'Sisäinen',
  check_availability_for_anyone: 'Tarkista saatavuus kaikille',
  store_not_found: 'Kauppaa ei löytynyt',
  appointment: 'Tapaamiset',
  event: 'Ryhmätapaamiset',
  resource: 'Resurssit',
  morgen: 'aamu',
  vormittag: 'aamupäivä',
  mittag: 'keskipäivä',
  nachmittag: 'iltapäivä',
  abend: 'ilta',
  nacht: 'yö-',
  free: 'vapaa',
  hoursShort: '',
  minutesShort: 'min',
  mondayLong: 'Maanantai',
  day1: 'MA',
  tuesdayLong: 'Tiistai',
  day2: 'TI',
  wednesdayLong: 'Keskiviikko',
  day3: 'KE',
  thursdayLong: 'Torstai',
  day4: 'TO',
  fridayLong: 'Perjantai',
  day5: 'PE',
  saturdayLong: 'Lauantai',
  day6: 'LA',
  sundayLong: 'Sunnuntai',
  day0: 'SU',
  Januar: 'Tammikuu',
  Februar: 'Helmikuu',
  März: 'Maaliskuu',
  April: 'Huhtikuu',
  Mai: 'Toukokuu',
  Juni: 'Kesäkuu',
  Juli: 'Heinäkuu',
  August: 'Elokuu',
  September: 'Syyskuu',
  Oktober: 'Lokakuu',
  November: 'Marraskuu',
  Dezember: 'Joulukuu',
  "shortMonths": {
    "Januar": "Tam",
    "Februar": "Hel",
    "März": "Maa",
    "April": "Huh",
    "Mai": "Tou",
    "Juni": "Kes",
    "Juli": "Hei",
    "August": "Elo",
    "September": "Syy",
    "Oktober": "Lok",
    "November": "Mar",
    "Dezember": "Jou"
  },
  du: 'sinä',
  sie: 'Sinä',
  havingFormDu: 'omistaa',
  havingFormSie: 'omistaa',
  yes: "Joo",
  no: "Ei",
  confirm: {
    title: 'Vahvista tilaus',
    confirmationText: 'Ostoskorissasi on {{ cartLength }} tapaamisia. Haluatko todella varata ne kaikki nyt?',
    confirmationTextSie: 'Ostoskorissasi on {{ cartLength }} tapaamisia. Haluatko todella varata ne kaikki nyt?',
    ok: 'Kirja',
    cancel: 'Peruuttaa'
  },
  internal: {
    search_customer: 'Hae olemassa olevaa asiakasta ja esitäytä lomake',
    selectPlaceholder: 'Etsi asiakas',
    customerDetails: 'Asiakkaan tiedot',
    noCustomer: 'Asiakkaita ei ole määritetty',
    create_customer: 'Luo asiakas'
  },
  common: {
    report_bug: 'Ilmoita virheestä',
    subscription_error_title: 'Ei tilausta!',
    subscription_error_desc: 'Tilausta ei valitettavasti löytynyt. Ota yhteyttä Calenson tukeen.',
    next: 'Seuraava',
    previous: 'Edellinen',
    reset: 'Nollaa sovellus',
    resetSuccess: 'Varausohjelmisto nollattiin onnistuneesti. Tilapäisesti tallennetut tiedot ovat nyt poissa.',
    loading: 'Ladataan ...',
    durationHour: 'h',
    durationMinute: 'm',
    clock: 'h',
    disclaimer: 'Tämän palvelun tarjoaa <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Kirja',
    choseBookType: 'Mitä haluaisit varata?',
    company: 'Yhtiö',
    prename: 'Etunimi',
    lastname: 'Sukunimi',
    email: 'Sähköposti',
    phone: 'Puhelin',
    address: 'Osoite',
    createAccount: 'Luo asiakastili (käyttäjätili kaikilla rekisteröinneillä)',
    noDateError: 'Joku muu on varannut haluamasi ajan, palaa takaisin ja valitse uusi aika.',
    devNotice: 'Varauswidget on yhdistetty DEV-taustajärjestelmään. Ole varovainen!',
    store_access_warning: 'Selaimen muistin käyttö on rajoitettu yksityisyysasetustesi vuoksi. Kaikki varaustyökalun toiminnot eivät ehkä ole käytettävissä.',
    save: "Tallenna",
    cancel: "Peruuta",
    additionalParticipantsUpTo: "Voit lisätä jopa {{ number }} vierasta",
    additionalGuest: 'Muita osallistujia',
    addAdditionalGuest: 'Lisää osallistujia',
    additionalGuestCartLabel: '{{ number }} muuta vierasta',
    additionalGuestsCartLabel: '{{ number }} muuta vierasta',
    widget_disabled: 'Varauswidget on tällä hetkellä poissa käytöstä',
    widget_disabled_description: 'Varauswidget on tilapäisesti poistettu käytöstä maksuongelmien vuoksi. Jos sinulla on kysyttävää, ota yhteyttä Calenson tukeen.',
    no_workers_widget_disabled_description: 'Aikoja ei voi tällä hetkellä varata, koska mikään resurssi ei ole käytössä.',
    workers_not_available: 'Varattavaa palvelua ei löytynyt',
    workers_not_available_description: 'Varattavissa olevaa palvelua ei tallennettu. Varmista, että vähintään yksi palvelu on saatavilla ja varattavalle resurssille (kyvylle) on määritetty. Palveluluokkaan syöttäminen ei riitä varaamiseen.',
    price_from_label: 'osoitteesta',
    close: 'Sulje',
    configuration_error: 'Konfigurointivirhe',
    resource_is_not_bookable: 'Valittu resurssi ei ole varattavissa.',
    verification_token_missing: 'Varaus-widgettiä käytetään sisäisessä tilassa, ja siksi se vaatii vahvistuskoodin. Ota yhteyttä Calenson tukeen.',
    invalid_verification_code: 'Sisäisen tilan käyttö vaatii voimassa olevan tunnuksen, varmista että tunnus on voimassa ja yritä uudelleen.',
    internal_appointment_booking_widget_disabled: "Sisäinen widget-palvelu on poistettu käytöstä kumppanin toimesta. Ota yhteyttä järjestelmänvalvojaasi saadaksesi lisätietoja.",
    external_appointment_booking_widget_disabled: "Widget-palvelu on poistettu käytöstä kumppanimme toimesta. Ota heihin yhteyttä saadaksesi lisätietoja.",
    external_event_booking_widget_disabled: "Widget-palvelu on poistettu käytöstä kumppanimme toimesta. Ota heihin yhteyttä saadaksesi lisätietoja.",
    conference_invalid_link: "Vaikuttaa siltä, että annettu konferenssilinkki on virheellinen. Tarkista linkki ja yritä uudelleen.",
    conference_expired_link: "Pahoittelumme, mutta tämä konferenssilinkki on vanhentunut. Ota yhteyttä järjestäjään saadaksesi lisäapua.",
    conference_not_belonging: "Hups, näyttää siltä, että tämä konferenssi on liitetty eri kumppaniin.",
    conference_booked: "Hupsis, näyttää siltä, että tämä konferenssilinkki on jo käytetty ja varattu. Jos sinulla on kysyttävää tai tarvitset apua, voit vapaasti ottaa yhteyttä järjestäjiin.",
    conference_invalid_configurations: "Konferenssi ei ole käytettävissä virheellisten sisäisten asetusten vuoksi. Saat lisäapua ottamalla yhteyttä järjestäjään.",
    location: "Sijainti",
    empty_string_validation: "Vain välilyönnit eivät ole sallittuja.",
    invalid_password: "Anna kelvollinen salasana."
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km päässä',
      search_store_postcode_helper_text: 'Etsi lähin kauppa antamalla postinumero',
      search_services_helper_text: 'Syötä hakusana etsiäksesi palveluita',
      title: 'Palvelut',
      chooseStore: 'Valitse kauppa',
      chooseService: 'Valitse palvelu',
      nextButton: 'Seuraava',
      search_store_by_postcode_placeholder: 'Syötä postinumero etsiäksesi oikean myymälän',
      create_lead: 'Mahdollisuus MH',
      expert_cap_button_label: 'Määrää CAP:n asiantuntijalle',
      appointment_booking_not_available_for_zipcode: 'Valitettavasti ajanvaraus ei ole saatavilla tälle postinumerolle. Luo uusi asiakas, jotta CAP voi ottaa heihin yhteyttä puhelimitse.'
    },
    step2: {
      title: 'Resurssi',
      chooseEmployee: 'Valitse ilmainen resurssi',
      name: 'Nimi',
      function: 'Toiminto',
      noSpecificWorker: 'Kuka tahansa',
      noSpecificFunction: 'Kaikki toiminnot',
      exception: "Määrittele työntekijän poikkeus",
      nextButton: 'Valitse päivämäärä/aika'
    },
    step3: {
      title: 'Päivämäärä',
      chooseDate: 'Valitse haluamasi päivämäärä',
      morning: 'Aamu',
      forenoon: 'Aamupäivä',
      noon: 'Keskipäivä',
      afternoon: 'Iltapäivä',
      evening: 'Ilta',
      night: 'Yö',
      infoMsg: 'Huomaa, että voit valita useita tapaamisia. Valitse vain haluamasi tapaamiset valintaruudun kautta. Ajanvaraukset lisätään sitten ostoskoriin.',
      appointment: 'Tapaaminen',
      employee: 'Resurssi',
      proposals: 'Ilmaiset tapaamiset',
      noFreeAppointments: 'Valitettavasti tälle päivälle ei ole vapaita aikoja!',
      nextButton: 'Seuraava',
      cartNotSupported: 'Tämä palveluntarjoaja tukee vain yhtä slotin rekisteröintiä varausta kohden.',
      cartSupported: 'Tämä palveluntarjoaja tukee useita rekisteröintejä varausta kohden.',
      workerSelectLable: "Valitse Henkilökohtainen Neuvonantaja:",
      workerSelectLinkPrefix: "Etkö löytänyt aikaa neuvonantajasi kanssa?",
      workerSelectShowAllLinkPostfix: "Näytä kaikki neuvonantajat",
      workerSelectHideAllLinkPostfix: "Piilota kaikki neuvonantajat"
    },
    step4: {
      comment_helper_text: 'Enintään 2000 merkkiä',
      title: 'Viedä loppuun',
      summary: 'Yhteenveto',
      company: 'Yhtiö',
      store: 'Store',
      employee: 'Resurssi',
      bookedServices: 'Palvelu',
      duration: 'Kesto',
      price: 'Hinta',
      finalPrice: 'Kustannukset',
      total: 'Kaikki yhteensä',
      yourData: 'Tietosi',
      disclaimer: 'Ole hyvä ja kirjoita henkilökohtaiset yhteystietosi. Kaikki *-merkityt kentät ovat pakollisia.',
      prename: {
        label: 'Esinimi',
        placeholder: 'Esinimi',
        error: {
          minChars: 'Etunimessä on oltava vähintään {{ requiredLength }} merkkiä.',
          required: 'Lisää etunimi.'
        }
      },
      lastname: {
        label: 'Sukunimi',
        placeholder: 'Sukunimi',
        error: {
          minChars: 'Sukunimessä on oltava vähintään {{ requiredLength }} merkkiä.',
          required: 'Lisää sukunimi.'
        }
      },
      phone: {
        label: 'Kännykkä',
        placeholder: '+4179xxxxxxxx',
        error: {
          pattern: 'Puhelinnumero voi sisältää vain numeroita.',
          minChars: 'Puhelinnumerossa on oltava vähintään {{ requiredLength }} numeroa.',
          required: 'Lisää puhelinnumero.'
        }
      },
      eMail: {
        label: 'Sähköposti',
        error: {
          pattern: 'Lisää kelvollinen sähköpostiosoite.',
          minChars: 'Sähköpostiosoitteessa on oltava vähintään {{ requiredLength }} merkkiä.',
          required: 'Lisää sähköpostiosoite.'
        }
      },
      message: {
        label: 'Viesti',
        placeholder: ''
      },
      internal_comment: {
        label: 'Sisäinen kommentti',
        placeholder: 'Lisää kommentteja sisäiseen käyttöön',
        comment_helper_text: 'Enintään 5000 merkkiä'
      },
      company_name: {
        label: 'Yrityksen nimi',
        error: {
          minChars: 'Tämä kenttä vaatii vähintään {{ requiredLength }} merkkiä.'
        }
      },
      customFields: {
        defaultOption: 'Valitse vaihtoehto',
        error: {
          maxChars: 'Tässä kentässä voi olla enintään {{ requiredLength }} merkkiä.',
          minChars: 'Tämä kenttä vaatii vähintään {{ requiredLength }} merkkiä.',
          required: 'Tämä kenttä on pakollinen.',
          questionisRequired: "{{ text }} on pakollinen",
          checkboxisRequired: "Tämä valintaruutu on valittava, jotta voit jatkaa."
        }
      },
      cart: {
        emptyCartWarning: 'Ostoskorissasi ei ole tällä hetkellä aikoja',
        removeIcon: 'Poista ajanvaraus ostoskorista',
        waiting_list: 'Odotuslista'
      },
      numberSelect: {
        increase: 'Lisää arvoa',
        decrease: 'Vähennä arvoa'
      }
    },
    finalpage: {
      successTitle: 'Varasit tapaamisen onnistuneesti',
      successMessageDu: 'Ajanvaraus lähetettiin onnistuneesti. Vahvistus lähetettiin joko sähköpostilla tai tekstiviestillä. <br> <br> Jos et jostain syystä pysty osallistumaan tilaisuuteen, peruuta tilaisuus sähköpostissa tai tekstiviestissä olevan peruutuslinkin kautta.',
      successMessageSie: 'Ajanvaraus lähetettiin onnistuneesti. Vahvistus lähetettiin joko sähköpostilla tai tekstiviestillä. <br> <br> Jos et jostain syystä pysty osallistumaan tilaisuuteen, peruuta tilaisuus sähköpostissa tai tekstiviestissä olevan peruutuslinkin kautta.',
      errorTitle: 'Tapahtui virhe.',
      errorMessage: 'Päivämäärää ei voitu varata. Yritä uudelleen.',
      errorMessageDu: 'Valitettavasti tapaamista ei voitu varata. Yritä uudelleen.',
      errorMessageSie: 'Valitettavasti tapaamista ei voitu varata. Yritä uudelleen.',
      bookNextAppointment: 'Varaa lisäaika',
      bookAppointmentAgain: 'Mene takaisin'
    },
    error: {
      noworkers: 'Valitettavasti tämä palveluntarjoaja ei vielä määrittänyt varattavia resursseja.'
    }
  },
  events: {
    list: "Lista",
    grid: "Ruudukko",
    noEvents: "Tämä palveluntarjoaja ei vielä määrittänyt tapahtumia.",
    hidden_events_message: "Tämän kumppanin tapahtumat ovat piilossa. Käytä rekisteröitymiseen suoraa linkkiä kumppanilta saamaasi tapahtumaan.",
    filter_result_null: "Kriteereitäsi vastaavia tuloksia ei ole.",
    step1: {
      title: "Tapahtumat",
      moreInformation: "Lisää tietoa",
      noFreeSlots: "Ei enää ilmaisia kolikkopelejä",
      noEvents: "Es wurden noch keine Events erstellt.",
      showDescription: "Näytä koko kuvaus",
      hideDescription: "Piilota kuvaus",
      book: "Kirja",
      noPlaceDefined: "Paikkaa ei ole määritelty",
      store_filter: "Tallentaa suodatin",
      category_filter: "Luokan suodatin",
      all_categories: "Kaikki"
    },
    step2: {
      title: 'Päivämäärä',
      slots: 'peliautomaatit',
      chooseDate: 'Valitse haluamasi päivämäärä',
      booked: 'Varattu',
      capacity: 'Saatavilla',
      noFreeDates: 'Tälle kumppanille ei ole ilmaisia tapahtumia',
      bookedOut: 'Tämä paikka on jo varattu',
      cartNotSupported: 'Tämä palveluntarjoaja tukee vain yhtä slotin rekisteröintiä varausta kohden.',
      cartSupported: 'Tämä palveluntarjoaja tukee useita rekisteröintejä varausta kohden.',
      alreadyBookedByUser: 'Olet jo varannut tämän paikan. Jos ongelmia ilmenee, nollaa sovellus.',
      waitinglist: 'jonotuslista: {{ capacity }} ilmainen',
      fully_booked: "Täysin varattu",
      regular_count_singular: '<b>1</b> vapaa paikka',
      regular_count_plural: '<b>{{ number }}</b> vapaata paikkaa',
      waiting_count_singular: "1 vapaa paikka jonotuslistalla",
      waiting_count_plural: "{{ number }} vapaata paikkaa jonotuslistalla",
      free_seats: 'Vapaat paikat',
      free_waiting_seats: 'Ilmaiset odotuslistapaikat',
      multiday_group_appointment: "Tämä on monipäiväinen ryhmätapaaminen"
    },
    step3: {
      title: 'Viimeistele',
      summary: 'Yhteenveto',
      company: 'Yhtiö',
      store: 'Store',
      employee: 'Resurssi',
      bookedEvent: 'Tapahtuma',
      date: 'Päivämäärä',
      time: 'Aika',
      price: 'Hinta',
      finalPrice: 'Kustannukset',
      total: 'Kaikki yhteensä',
      yourData: 'Tietosi',
      disclaimer: 'Anna yhteystietosi. Kaikki *-merkityt kentät ovat pakollisia.',
      cart: {
        emptyCartWarning: 'Ostoskorissa ei ole tällä hetkellä tapahtumia.',
        waiting_list: 'Odotuslista'
      }
    },
    finalpage: {
      successTitle: 'Ilmoittautuminen onnistui.',
      successMessageDu: 'Ilmoittautuminen tapahtumaan onnistui. Vahvistus lähetettiin joko sähköpostilla tai tekstiviestillä. <br> <br> Jos et jostain syystä pysty osallistumaan tilaisuuteen, peruuta tilaisuus sähköpostissa tai tekstiviestissä olevan peruutuslinkin kautta.',
      successMessageSie: 'Ilmoittautuminen tapahtumaan onnistui. Vahvistus lähetettiin joko sähköpostilla tai tekstiviestillä. <br> <br> Jos et jostain syystä pysty osallistumaan tilaisuuteen, peruuta tilaisuus sähköpostissa tai tekstiviestissä olevan peruutuslinkin kautta.',
      errorTitle: 'Tapahtui virhe.',
      errorMessage: 'Rekisteröinnin aikana tapahtui virhe. Yritä uudelleen.',
      bookNextEvent: 'Varaa lisäilmoittautuminen',
      bookEventAgain: 'Mene takaisin'
    }
  },
  resources: {
    noResources: 'Tämä palveluntarjoaja ei vielä määrittänyt resursseja.',
    step1: {
      title: 'Resurssit',
      chooseResource: 'Valitse Resurssit'
    },
    step2: {
      title: 'Päivämäärä',
      startDate: 'Aloituspäivämäärä: ',
      endDate: 'Päättymispäivä: ',
      startTime: 'Valitse aloitusaika',
      endTime: 'Valitse lopetusaika',
      resetCalendar: 'Nollaa kalenteri',
      noFreeResources: 'Varattavia resursseja ei löytynyt ajanjaksolle',
      selectDate: 'Valitse päivämäärä tai ajanjakso'
    },
    step3: {
      title: 'Viedä loppuun',
      summary: 'Yhteenveto',
      company: 'Yhtiö',
      store: 'Store',
      employee: 'Resurssi',
      bookedEvent: 'Tapahtuma',
      date: 'Päivämäärä',
      time: 'Aika',
      price: 'Hinta',
      finalPrice: 'Kustannukset',
      total: 'Kaikki yhteensä',
      yourData: 'Tietosi',
      disclaimer: 'Anna yhteystietosi. Kaikki *-merkityt kentät ovat pakollisia.',
      prename: {
        label: 'Etunimi',
        placeholder: 'Etunimi',
        error: {
          minChars: 'Etunimen on oltava vähintään {{ requiredLength }} merkkiä pitkä.',
          required: 'Ilmoita etunimi.'
        }
      },
      lastname: {
        label: 'Sukunimi',
        placeholder: 'Sukunimi',
        error: {
          minChars: 'Sukunimen on oltava vähintään {{ requiredLength }} merkkiä pitkä.',
          required: 'Anna sukunimi.'
        }
      },
      phone: {
        label: 'Matkapuhelin',
        placeholder: '+4179xxxxxxxx',
        error: {
          pattern: 'Puhelinnumero voi sisältää vain numeroita.',
          minChars: 'Puhelinnumeron on oltava vähintään {{ requiredLength }} Sisältää merkkejä.',
          required: 'Anna puhelinnumero.'
        }
      },
      eMail: {
        label: 'sähköposti',
        error: {
          pattern: 'Ole hyvä ja syötä toimiva sähköpostiosoite.',
          minChars: 'Sähköpostiosoitteen on oltava vähintään {{ requiredLength }} merkkiä pitkä.',
          required: 'Ole hyvä ja syötä sähköpostiosoite.'
        }
      },
      message: {
        label: 'Viesti',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Valitse mikä tahansa vaihtoehto',
        error: {
          minChars: 'Tämä kenttä vaatii {{ requiredLength }} merkkiä.',
          required: 'Tämä kenttä on pakollinen.'
        }
      },
      cart: {
        emptyCartWarning: 'Ostoskorissa ei ole tällä hetkellä resursseja.'
      },
      From: 'Lähettäjä',
      To: 'Vastaanottaja'
    },
    finalpage: {
      successTitle: 'Varasit onnistuneesti resurssin',
      successMessageDU: 'Jos muutat mieltäsi, peruuta resurssivarauksesi vähintään {{ leadTime }} tuntia ennen varauksen tekemistä.',
      successMessageSIE: 'Jos muutat mieltäsi, peruuta resurssivarauksesi vähintään {{ leadTime }} tuntia ennen varauksen tekemistä.',
      errorTitle: 'Tapahtui virhe.',
      errorMessage: 'Resurssia ei voitu varata. Yritä uudelleen.',
      bookNextEvent: 'Varaa lisäresursseja',
      bookEventAgain: 'Mene takaisin'
    }
  },
  customer: {
    prename: {
      label: 'Etunimi',
      placeholder: 'Etunimi',
      error: {
        minChars: 'Etunimen on oltava vähintään {{ requiredLength }} merkkiä pitkä.',
        required: 'Kirjoita etunimesi, ole hyvä.'
      }
    },
    lastname: {
      label: 'Sukunimi',
      placeholder: 'Sukunimi',
      error: {
        minChars: 'Sukunimen on oltava vähintään {{ requiredLength }} merkkiä pitkä.',
        required: 'Anna sukunimesi.'
      }
    },
    phone: {
      label: 'Puhelinnumero',
      placeholder: '+4179......',
      error: {
        pattern: 'Puhelinnumerossa saa olla vain numeroita.',
        minChars: 'Puhelinnumerossa on oltava vähintään {{ requiredLength }} numeroa.',
        required: 'Ole hyvä ja anna puhelinnumerosi.',
        invalid: 'Anna voimassa oleva matkapuhelinnumero.'
      }
    },
    eMail: {
      label: 'Sähköposti',
      error: {
        pattern: 'Ole hyvä ja syötä toimiva sähköpostiosoite.',
        minChars: 'Sähköpostiosoitteessa on oltava vähintään {{ requiredLength }} merkkiä.',
        required: 'Sähköpostiosoite vaaditaan.'
      }
    },
    street: {
      label: 'Katu / nro.',
      placeholder: 'Kadun nimi',
      error: {
        minChars: 'Kadun tulee sisältää vähintään {{ requiredLength }} merkkiä.',
        required: 'Anna kadun nimi.'
      }
    },
    zip: {
      label: 'POSTINUMERO',
      placeholder: 'Postinumero',
      error: {
        minChars: 'Postinumerossa on oltava vähintään {{ requiredLength }} merkkiä.',
        required: 'Anna postinumerosi.',
        pattern: "Anna kelvollinen postinumero, kiitos.",
      }
    },
    city: {
      label: 'Kaupunki',
      placeholder: 'kaupungin nimi',
      error: {
        minChars: 'Kaupungissa on oltava vähintään {{ requiredLength }} merkkiä.',
        required: 'Anna kaupunkisi nimi.'
      }
    },
    message: {
      label: 'Viesti',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Valitse vaihtoehto',
      error: {
        minChars: 'Tämä kenttä vaatii vähintään {{ requiredLength }} merkkiä.',
        required: 'Tämä kenttä pitää täyttää.'
      }
    },
    captcha: {
      required: 'Tämä kenttä pitää täyttää.',
      invalid: 'Syötä kelvollinen captcha.',
      valid: 'Captcha sovitettu onnistuneesti.',
      placeholder: 'Kirjoita yllä oleva teksti'
    }
  },
  stripe: {
    stripe_title: 'Maksa seuraava vierailusi verkossa',
    submit_payment: 'Lähetä maksu',
    stripe_label: 'Luottokortti tai pankkikortti',
    online_payment_optional: 'Tässä vaiheessa sinulla on mahdollisuus päättää seuraava vierailusi juuri nyt. Sinulta ei peritä lisäkuluja. Maksu suoritetaan suojatun palveluntarjoajan (Stripe) kautta. Emme tallenna luottokorttitietoja palvelimillemme.',
    online_payment_mandatory: 'Varauksen viimeistelemiseksi pyydämme sinua maksamaan seuraavan vierailusi nyt. Sinulta ei peritä lisäkuluja. Maksu suoritetaan suojatun palveluntarjoajan (Stripe) kautta. Emme tallenna luottokorttitietoja palvelimillemme.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Valitse tietty päivä kalenterista nähdäksesi mahdolliset päivämäärät.",
    no_events_in_month: "Valitettavasti valitussa kuukaudessa ei ole enää vapaita aikoja.",
    no_events: "Valitettavasti tällä tapahtumalla ei ole varattavissa olevia ikkunoita tulevaisuudessa. Siksi kalenteria ei näytetä päivämäärän valitsemiseksi.",
    waitinglist_enabled_message: "Jotkin aikavälien paikat on jo varattu täyteen. Jonotuslistalle voi kuitenkin ilmoittautua. Heti kun osallistuminen peruuntuu, seuraava jonotuslistan paikka nousee ylöspäin ja saa vahvistuksen osallistumisesta.",
    waitinglist_booking_message: "Paljon kiitoksia! Olemme hyväksyneet varauksesi onnistuneesti. Koska olet nyt jonotuslistalla ainakin yhteen tapahtumaan, saat meiltä vain vahvistuksen osallistumisestasi, jos toinen osallistuja ei pääse tapahtumaan. Tässä tapauksessa sinulle ilmoitetaan sähköpostitse. Valitettavasti tilanpuutteen vuoksi et pääse tapahtumaan ilman virallista vahvistusta sivustoltamme.",
    window_is_fully_booked: "Tämä aikaikkuna on täynnä (mukaan lukien jonotuslista).",
    waitinglist_is_available: "Tämä aika on täynnä, mutta odotuslistalla on vielä <b>{{ number }}</b> vapaata paikkaa.",
    no_bookable_dates_message: "Valitettavasti kuukaudessa {{ month }} ei ole enää varattavia päiviä. Pitäisikö meidän etsiä vapaita päivämääriä tulevina kuukausina?",
    no_slots_dates_message: "Valitettavasti kuukaudessa {{ month }} ei ole enää varattavissa olevia aikoja. Pitäisikö meidän etsiä tulevina kuukausina vapaita aikoja?",
    no_slots_dates_reached_message: "Valitettavasti kuukaudessa {{ month }} ei ole enää varattavissa olevia aikoja. Enimmäisvarausaika on saavutettu.",
    no_slots_years_reached_message: "Valitettavasti vuoden {{ year }} kaikki varattavat ajat on jo varattu. Varattavissa oleva aika on täyttynyt.",
    no_bookable_dates_reached_message: "Valitettavasti kuukaudessa {{ month }} ei ole enää varattavia päivämääriä. Enimmäisvarausaika on saavutettu.",
    xss_error_message: "Tämä teksti sisältää HTML-tageja, joita ei tueta.",
    slot_already_reserved_by_other_error: "Sillä välin varaamasi aika on varattu jonkun toisen toimesta. Valitse toinen aika ja yritä myöhemmin uudelleen.",
    failed_to_reserve_slot_error: "Jokin meni pieleen varauksen aikana. Yritä myöhemmin uudelleen!",
    reserved_by_someone: "Varattu jonkun toisen toimesta"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Lunasta kuponki',
    apply_coupon: 'Käytä kuponki',
    coupon: 'Kuponki',
    credit_or_debit_card: 'Luotto- tai pankkikortti',
    invalid_coupon: 'Virheellinen kuponki',
    applied_successfully: 'Sovellettu onnistuneesti'
  },
  tax_info: {
    taxes: '(sis. ALV:n {{percentage}} %)'
  },
  new_alert_component: {
    x_day_ago: 'x päivä sitten',
    x_days_ago: 'x päivää sitten',
    x_minute_ago: 'x minuutti sitten',
    x_minutes_ago: 'x minuuttia sitten',
    x_hour_ago: 'x tunti sitten',
    x_hours_ago: 'x tuntia sitten',
    x_second_ago: 'x sekunti sitten',
    x_seconds_ago: 'x sekuntia sitten',
    x_week_ago: 'x viikko sitten',
    x_weeks_ago: 'x viikkoa sitten',
    x_month_ago: 'x kuukausi sitten',
    x_months_ago: 'x kuukautta sitten',
    x_year_ago: 'x vuosi sitten',
    x_years_ago: 'x vuotta sitten'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Ennen kuin varaus on mahdollista, avoin summa on maksettava PayPalilla.',
    paypal: 'PayPal',
    paypal_paid_successfully: 'Summa maksettiin onnistuneesti PayPalilla. Ajan voi nyt varata.',
    paypal_paid_failed: 'Valitettavasti summaa ei voitu maksaa PayPalilla. Ota yhteyttä Calenson tukeen.',
    or: 'tai',
    browser: 'Selaa',
    choose_file: 'Valitse tiedosto',
    clear: 'Asia selvä',
    meeting_type: 'Missä tapaaminen tulisi järjestää?',
    email: 'Sähköposti',
    sms: 'tekstiviesti',
    both_sms_email: 'Sähköposti ja SMS',
    notification_type: 'Ilmoituksen tyyppi',
    payment_title: 'Maksu',
    payment_subtitle: 'Päätä, kuinka haluat maksaa palveluistasi',
    payment_subtitle_sie: 'Päätä, kuinka haluat maksaa palveluistasi',
    summary_title: 'Yhteenveto',
    summary_subtitle: 'Tarkista tilauksesi ja lähetä se',
    summary_subtitle_sie: 'Tarkista tilauksesi ja lähetä se',
    payment_type: 'Maksutapa',
    creditcard: 'Luottokortti',
    invoice: 'Lasku',
    lexoffice: 'Lasku (LexOffice)',
    store: 'Sivulla',
    apply_coupon: 'Haluaisin käyttää alennuskoodia',
    choose_payment_method: 'Valitse maksutapa',
    tax: 'sis. {{ rate }} % ALV',
    subtotal: 'Välisumma',
    personal_form_title: 'Henkilökohtaiset tiedot',
    personal_form_subtitle: 'Kerro meille jotain sinusta',
    personal_form_subtitle_sie: 'Kerro meille jotain sinusta',
    inclusive: 'mukaan lukien',
    paymentMandatory: 'Palveluntarjoaja on määritellyt, että palvelu on maksettava verkossa etukäteen. Valitse siis maksutapa.',
    file_upload: 'Tiedoston lataus',
    file_upload_helper_text: 'Useita tiedostoja voidaan liittää',
    file_was_uploaded: '"{{name}}" lähetettiin onnistuneesti',
    max_file_size_5mb: 'Ladattu tiedosto on liian suuri (enintään 5 Mt sallittu).',
    malware_found: 'Virustarkistuksessa ilmeni ongelma (Pääsy estetty). Ota yhteyttä Calenso-tukeen!',
    multiple_file_upload_helper_text: 'Enintään 5 tiedostoa voidaan ladata. Ladattava tiedosto ei saa olla suurempi kuin 5 Mt.',
    file_uploading: 'Tiedoston lataus on vielä kesken. Odota, kunnes toiminto on päättynyt, ja yritä sitten varata uudelleen..',
    single_file_upload_helper_text: '1 tiedosto voidaan ladata. Ladattava tiedosto ei saa olla suurempi kuin 5 Mt.',
    maximum_x_files: 'Enintään {{fileLength}} tiedostoa voidaan ladata.',
    document_invalid_file_type: "Virheellinen tiedostotyyppi. Vain PDF- ja Excel-tiedostot ovat sallittuja.",
    image_invalid_file_type: "Virheellinen tiedostotyyppi, lataa kelvollinen kuvatiedosto.",
    request_sms_code: 'Varauskoodi',
    mobile_number_must_be_verified: 'Puhelinnumero on onnistuneesti vahvistettu.',
    verification_code: 'Vahvistuskoodi',
    sms_code_verification_failed_msg: 'Syötetty koodi ei valitettavasti vastaa tekstiviestillä lähettämäämme koodia.',
    check: 'Tarkistaa',
    phone_number_verified_successfully: 'Puhelinnumero on vahvistettu.',
    resend_sms_helper_msg_1: 'En ole saanut koodia.',
    resend_sms_helper_msg_2: 'Pyydä koodi uudelleen.',
    resend_sms_successfully: 'Tekstiviestikoodi lähetetty onnistuneesti',
    exceeded_sms_verification_limit_msg: 'Tämä numero on ylittänyt vahvistuskoodien enimmäismäärän. Ota yhteyttä Calenson tukeen (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Tervehdys',
    mr: 'Herra.',
    ms: 'Neiti.',
    other: 'muu',
    select_payment_method: 'Valitse maksusyy',
    accept_data_policy: 'Hyväksyn ja olen lukenut <a href="https://calenso.com/agb/" target="_blank">Calenson käyttöehdot</a> ja <a href="https://calenso.com/ datenschutz/" target="_blank">Tietosuojakäytäntö</a> tietojeni käyttöön tapaamisen varauksen yhteydessä.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Napauta maksaaksesi Saferpayn avulla',
    payment_success_title: 'Onnistunut maksu',
    payment_success_message: 'Avoin summa on maksettu onnistuneesti {{ paymentMethod }}. Ajanvaraus voidaan nyt varata. Jos varausta ei ole tehty, summaa ei veloiteta..',
    title: 'Otsikko',
    country: 'Maa',
    title_aria_describedby: "Erityinen arvo, joka tukee tohtori, tohtori. med., Prof.",
    salutation_aria_describedby: "Tervehdysmuoto, joka tukee Herra, Rouva ja muita",
    prename_aria_describedby: "Etunimi tai etunimi",
    lastname_aria_describedby: "Sukunimi",
    notification_type_aria_describedby: "Voit määrittää varaustapahtuman aikana, miten haluat saada ilmoituksen varauksesta.",
    email_describedby: "Sähköposti",
    phoneNumber_aria_describedby: "Puhelinnumero",
    smsPhoneCode_aria_describedby: "OTP puhelinnumeron vahvistamiseksi",
    mobileNumber_aria_describedby: "Matkapuhelinnumero",
    smsMobileCode_aria_describedby: "OTP matkapuhelinnumeron vahvistamiseksi",
    street_aria_describedby: "Osoite",
    zip_aria_describedby: "Postinumero",
    city_aria_describedby: "Kaupunki",
    country_aria_describedby: "Maa",
    comment_aria_describedby: "Lisäkommentit, jos sinulla on",
    internal_comment_aria_describedby: "Sisäinen kommentti (vain sisäiseen käyttöön)",
    company_aria_describedby: "Yrityksen nimi",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Kyllä, etsi.',
    remove: 'Poista'
  },
  add: 'Lisätä',
  compact: {
    no_service: 'Tällä hetkellä ei voida varata tapaamisia, koska vastaavaa palvelua ei ole löydetty.',
    navigation: {
      date: 'Päivämäärä',
      date_slots: 'Päivämäärä ja kellonaika',
      personal_info: 'Henkilökohtaiset tiedot'
    }
  }
};
