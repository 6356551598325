export const pl_PL = {
  customer_type: "Typ klienta",
  manage_appointment_now: "Zarządzaj spotkaniem teraz",
  maintenance_page_title: "Calenso jest właśnie dla Ciebie ulepszane",
  maintenance_page_subtitle: "Calenso jest aktualizowane do najnowszej wersji. ",
  maintenance_page_button: "Do strony stanu",
  load_more: "Załaduj więcej",
  show_more: "Pokaż więcej",
  internal_label: "Wewnętrzny",
  check_availability_for_anyone: "Sprawdź dostępność dla każdego",
  store_not_found: "Nie znaleziono lokalizacji",
  appointment: "Spotkania",
  event: "Spotkania grupowe",
  resource: "Zasoby",
  morgen: "Poranek",
  vormittag: "Przedpołudnie",
  mittag: "Południe",
  nachmittag: "Popołudnie",
  abend: "Wieczór",
  nacht: "Noc",
  free: "Bezpłatne",
  hoursShort: "",
  minutesShort: "M",
  mondayLong: "Poniedziałek",
  day1: "PON",
  tuesdayLong: "Wtorek",
  day2: "WT",
  wednesdayLong: "Środa",
  day3: "ŚR",
  thursdayLong: "Czwartek",
  day4: "CZW",
  fridayLong: "Piątek",
  day5: "PT",
  saturdayLong: "Sobota",
  day6: "SOB",
  sundayLong: "Niedziela",
  day0: "ND",
  Januar: "Styczeń",
  Februar: "Luty",
  März: "Marzec",
  April: "Kwiecień",
  Mai: "Maj",
  Juni: "Czerwiec",
  Juli: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  Oktober: "Październik",
  November: "Listopad",
  Dezember: "Grudzień",
  "shortMonths": {
    "Januar": "Sty",
    "Februar": "Lut",
    "März": "Mar",
    "April": "Kwi",
    "Mai": "Maj",
    "Juni": "Cze",
    "Juli": "Lip",
    "August": "Sie",
    "September": "Wrz",
    "Oktober": "Paź",
    "November": "Lis",
    "Dezember": "Gru"
  },
  du: "Ty",
  sie: "Ty",
  havingFormDu: "Posiadać",
  havingFormSie: "Posiadać",
  yes: "Tak",
  no: "Nie",
  confirm: {
    title: "Potwierdzić zamówienie",
    confirmationText: "Ty masz {{ cartLength }} terminy w koszyku. ",
    confirmationTextSie: "Twoje masz {{ cartLength }} terminy w koszyku. ",
    ok: "Książka",
    cancel: "Anulować"
  },
  internal: {
    search_customer: "Wyszukaj istniejącego klienta i wypełnij formularz",
    selectPlaceholder: "Wyszukaj klienta",
    customerDetails: "Szczegóły klienta",
    noCustomer: "Brak skonfigurowanych klientów",
    create_customer: "Utwórz klienta"
  },
  common: {
    report_bug: "Zgłoś błąd",
    subscription_error_title: "Bez abonamentu!",
    subscription_error_desc: "Przepraszamy, nie znaleziono subskrypcji. ",
    next: "Dalej",
    previous: "Wstecz",
    reset: "Zresetuj aplikację",
    resetSuccess: "Oprogramowanie do rezerwacji zostało pomyślnie zresetowane. ",
    loading: "Ładowanie ...",
    durationHour: "H",
    durationMinute: "M",
    clock: "H",
    disclaimer: "Ta usługa jest świadczona przez <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.",
    book: "Zarezerwuj",
    choseBookType: "Co chciałbyś zarezerwować?",
    company: "Firma",
    prename: "Imię",
    lastname: "Nazwisko",
    email: "E-mail",
    phone: "Telefon",
    address: "Adres",
    createAccount: "Utwórz konto klienta (konto użytkownika ze wszystkimi rejestracjami)",
    noDateError: "Żądane spotkanie zostało zarezerwowane przez kogoś innego, wróć i wybierz nowe spotkanie.",
    devNotice: "Widżet rezerwacji jest połączony z systemem zaplecza DEV.",
    store_access_warning: 'Dostęp do pamięci przeglądarki jest ograniczony ze względu na ustawienia prywatności. Nie wszystkie funkcje widżetu rezerwacji mogą być dostępne.',
    save: "Zapisz",
    cancel: "Anuluj",
    additionalParticipantsUpTo: "Możesz dodać do {{ number }} gości",
    additionalGuest: "Dodatkowi uczestnicy",
    addAdditionalGuest: "Dodaj uczestników",
    additionalGuestCartLabel: "{{ number }} dodatkowy gość",
    additionalGuestsCartLabel: "{{ number }} dodatkowi goście",
    widget_disabled: "Widżet rezerwacji jest obecnie wyłączony",
    widget_disabled_description: "Widżet rezerwacji jest tymczasowo wyłączony. ",
    no_workers_widget_disabled_description: "Obecnie nie można zarezerwować żadnych spotkań, ponieważ żaden zasób nie jest włączony.",
    workers_not_available: "Nie znaleziono usługi, którą można zarezerwować",
    workers_not_available_description: "Nie zarejestrowano żadnej usługi, którą można zarezerwować. ",
    price_from_label: "z",
    close: "Zamknij",
    configuration_error: "Błąd konfiguracji",
    resource_is_not_bookable: "Wybranego zasobu nie można zarezerwować.",
    verification_token_missing: "Widżet rezerwacji jest używany w trybie wewnętrznym i dlatego wymaga tokena weryfikacyjnego. ",
    invalid_verification_code: "Dostęp do widżetu w trybie wewnętrznym wymaga ważnego tokenu, upewnij się, że token jest ważny, i spróbuj ponownie.",
    internal_appointment_booking_widget_disabled: "Usługa widgetu wewnętrznego została wyłączona przez partnera. Skontaktuj się z administratorem systemu, aby uzyskać więcej informacji.",
    external_appointment_booking_widget_disabled: "Usługa widgetu została wyłączona przez naszego partnera. Skontaktuj się z nimi, aby uzyskać więcej informacji.",
    external_event_booking_widget_disabled: "Usługa widgetu została wyłączona przez naszego partnera. Skontaktuj się z nimi, aby uzyskać więcej informacji.",
    conference_invalid_link: "Wygląda na to, że podany link do konferencji jest nieprawidłowy. Proszę sprawdź link i spróbuj ponownie.",
    conference_expired_link: "Przykro nam, ale ten link do konferencji wygasł. Skontaktuj się z organizatorem w celu uzyskania dalszej pomocy.",
    conference_not_belonging: "Ups, wygląda na to, że ta konferencja jest powiązana z innym partnerem.",
    conference_booked: "Ups, wygląda na to, że ten link do konferencji został już użyty i zarezerwowany. Jeśli masz pytania lub potrzebujesz pomocy, śmiało skontaktuj się z organizatorami.",
    conference_invalid_configurations: "Konferencja jest niedostępna z powodu nieprawidłowych wewnętrznych konfiguracji. W celu uzyskania dalszej pomocy, skontaktuj się z organizatorem.",
    location: "Lokalizacja",
    empty_string_validation: "Same spacje nie są dozwolone.",
    invalid_password: "Wprowadź poprawne hasło."
  },
  appointments: {
    step1: {
      distance_from_store: "{{ distance }} km stąd",
      search_store_postcode_helper_text: "Wpisz kod pocztowy, aby wyszukać najbliższą lokalizację",
      search_services_helper_text: "Wprowadź wyszukiwane hasło, aby wyszukać usługi",
      title: "Usługa",
      chooseStore: "Wybierz lokalizację",
      chooseService: "Wybierz usługę",
      nextButton: "Dalej",
      search_store_by_postcode_placeholder: "Wpisz kod pocztowy, aby wyszukać lokalizację",
      create_lead: "Okazja MH",
      expert_cap_button_label: "Przydziel do eksperta CAP",
      appointment_booking_not_available_for_zipcode: "Przepraszamy, rezerwacja terminu nie jest dostępna dla tego kodu pocztowego. "
    },
    step2: {
      title: "Pomoc",
      chooseEmployee: "Wybierz bezpłatny zasób",
      name: "Nazwa",
      function: "Funkcjonować",
      noSpecificWorker: "Ktoś",
      noSpecificFunction: "Wszystkie funkcje",
      exception: "Zdefiniuj wyjątek dla pracownika",
      nextButton: "Wybierz datę/godzinę"
    },
    step3: {
      title: "Data",
      chooseDate: "Wybierz preferowaną datę",
      morning: "Poranek",
      forenoon: "Przedpołudnie",
      noon: "Południe",
      afternoon: "Popołudnie",
      evening: "Wieczór",
      night: "Noc",
      infoMsg: "Pamiętaj, że istnieje możliwość wyboru wielu terminów. ",
      appointment: "Spotkanie",
      employee: "Pomoc",
      proposals: "Darmowe terminy",
      noFreeAppointments: "Niestety w tym dniu nie ma wolnych terminów!",
      nextButton: "Dalej",
      cartNotSupported: "Ten dostawca obsługuje tylko jedną rejestrację terminu na rezerwację.",
      cartSupported: "Ten dostawca obsługuje wiele rejestracji na rezerwację.",
      workerSelectLable: "Wybierz Doradcę Osobistego:",
      workerSelectLinkPrefix: "Nie znalazłeś spotkania z doradcą?",
      workerSelectShowAllLinkPostfix: "Pokaż wszystkich doradców",
      workerSelectHideAllLinkPostfix: "Ukryj wszystkich doradców"
    },
    step4: {
      comment_helper_text: "Maksymalnie 2000 znaków",
      title: "Zakończ",
      summary: "Podsumowanie",
      company: "Firma",
      store: "Lokalizacja",
      employee: "Pomoc",
      bookedServices: "Praca",
      duration: "Czas trwania",
      price: "Cena",
      finalPrice: "Cena końcowa",
      total: "Suma",
      yourData: "Twoje dane",
      disclaimer: "Proszę podać swoje osobiste dane kontaktowe. ",
      prename: {
        label: "Imię",
        placeholder: "Imię",
        error: {
          minChars: "Imię wymaga co najmniej {{ requiredLength }} znaków.",
          required: "Wpisz imię."
        }
      },
      lastname: {
        label: "Nazwisko",
        placeholder: "Nazwisko",
        error: {
          minChars: "Nazwisko wymaga co najmniej {{ requiredLength }} znaków.",
          required: "Wpisz nazwisko."
        }
      },
      phone: {
        label: "Telefon komórkowy",
        placeholder: "4179xxxxxxx",
        error: {
          pattern: "Numer telefonu może zawierać tylko cyfry.",
          minChars: "Numer telefonu wymaga co najmniej {{ requiredLength }} cyfr.",
          required: "Wprowadź numer telefonu."
        }
      },
      eMail: {
        label: "E-mail",
        error: {
          pattern: "Proszę podać poprawny adres e-mail.",
          minChars: "Adres e-mail wymaga co najmniej {{ requiredLength }} znaków.",
          required: "Wpisz adres e-mail."
        }
      },
      message: {
        label: "Wiadomość",
        placeholder: ""
      },
      internal_comment: {
        label: "Komentarz wewnętrzny",
        placeholder: "Dodaj komentarze do użytku wewnętrznego",
        comment_helper_text: "Maksymalnie 5000 znaków."
      },
      company_name: {
        label: "Nazwa firmy",
        error: {
          minChars: "Too pole wymaga wprowadzenia min. {{ requiredLength }} znaków."
        }
      },
      customFields: {
        defaultOption: "Wybierz opcję",
        error: {
          maxChars: 'Too pole wymaga wprowadzenia maks. {{ requiredLength }} znaków.',
          minChars: "Too pole wymaga wprowadzenia min. {{ requiredLength }} znaków.",
          required: "Too pole jest obowiązkowe",
          questionisRequired: "{{ text }} jest obowiązkowe",
          checkboxisRequired: "To pole wyboru musi być zaznaczone, aby kontynuować."
        }
      },
      cart: {
        emptyCartWarning: "Obecnie w koszyku nie ma żadnych spotkań.",
        removeIcon: "Usuń spotkanie z koszyka.",
        waiting_list: "Lista oczekujących"
      },
      numberSelect: {
        increase: 'Zwiększ wartość',
        decrease: 'Zmniejsz wartość'
      }
    },
    finalpage: {
      successTitle: "Pomyślnie zarezerwowałeś spotkanie",
      successMessageDu: "Rezerwacja spotkania została pomyślnie przesłana.  <br> <br> Jeśli z jakiegokolwiek powodu nie możesz wziąć udziału w wydarzeniu, anuluj je, korzystając z linku do anulowania w wiadomości e-mail lub SMS.",
      successMessageSie: "Rezerwacja spotkania została pomyślnie przesłana.  <br> <br> Jeśli z jakiegokolwiek powodu nie możesz wziąć udziału w wydarzeniu, anuluj je, korzystając z linku do anulowania w wiadomości e-mail lub SMS.",
      errorTitle: "Wystąpił błąd.",
      errorMessage: "Nie udało się zarezerwować terminu. ",
      errorMessageDu: 'Przepraszamy, nie udało się zarezerwować spotkania. Spróbuj ponownie.',
      errorMessageSie: 'Przepraszamy, spotkanie nie mogło zostać zarezerwowane. Spróbuj ponownie.',
      bookNextAppointment: "Zarezerwuj dodatkowe spotkanie",
      bookAppointmentAgain: "Wróć"
    },
    error: {
      noworkers: "Niestety, ten dostawca nie zdefiniował jeszcze żadnych zasobów, które można zarezerwować."
    }
  },
  events: {
    list: "Lista",
    grid: "Siatka",
    noEvents: "Ten dostawca nie określił jeszcze żadnych wydarzeń.",
    hidden_events_message: "Wydarzenia tego partnera są ukryte. ",
    filter_result_null: "Nie ma wyników spełniających Twoje kryteria.",
    step1: {
      title: "Wydarzenia",
      moreInformation: "Więcej informacji",
      noFreeSlots: "Nie ma już wolnych terminów",
      noEvents: "Nie utworzono jeszcze żadnych wydarzeń.",
      showDescription: "Pokaż cały opis",
      hideDescription: "Ukryj opis",
      book: "Książka",
      noPlaceDefined: "Nie określono miejsca",
      store_filter: "Przechowuj filtr",
      category_filter: "Filtr kategorii",
      all_categories: "Wszystko"
    },
    step2: {
      title: "Data",
      slots: "Terminy",
      chooseDate: "Wybierz preferowaną datę",
      booked: "Zarezerwowane",
      capacity: "Dostępne",
      noFreeDates: "Nie ma bezpłatnych wydarzeń dla tego partnera",
      bookedOut: "To miejsce jest już zarezerwowane",
      cartNotSupported: "Ten dostawca obsługuje tylko jedno miejsce na rezerwację.",
      cartSupported: "Ten dostawca obsługuje wiele rejestracji na rezerwację.",
      alreadyBookedByUser: "To miejsce zostało już przez Ciebie zarezerwowane. ",
      waitinglist: "Lista oczekujących: {{ capacity }} bezpłatny",
      fully_booked: "W pełni zarezerwowane",
      regular_count_singular: "<b>1</b> wolne miejsce",
      regular_count_plural: "<b>{{ number }}</b> wolne miejsca",
      waiting_count_singular: "1 wolne miejsce na liście oczekujących",
      waiting_count_plural: "{{ number }} wolnych miejsc na liście oczekujących",
      free_seats: "Wolne miejsca",
      free_waiting_seats: "Wolne miejsca na liście oczekujących",
      multiday_group_appointment: "To jest wielodniowe spotkanie grupowe"
    },
    step3: {
      title: "Zakończ",
      summary: "Podsumowanie",
      company: "Firma",
      store: "Lokalizacja",
      employee: "Pomoc",
      bookedEvent: "Wydarzenie",
      date: "Data",
      time: "Czas",
      price: "Cena",
      finalPrice: "Cena końcowa",
      total: "Suma",
      yourData: "Twoje dane",
      disclaimer: "Proszę podać swoje dane kontaktowe. ",
      cart: {
        emptyCartWarning: "Obecnie w koszyku nie ma żadnych wydarzeń.",
        waiting_list: "Lista oczekujących"
      }
    },
    finalpage: {
      successTitle: "Rejestracja została pomyślnie przesłana.",
      successMessageDu: "Rejestracja na wydarzenie została pomyślnie przesłana.  <br> <br> Jeśli z jakiegokolwiek powodu nie możesz wziąć udziału w wydarzeniu, anuluj je, korzystając z linku do anulowania w wiadomości e-mail lub SMS.",
      successMessageSie: "Rejestracja na wydarzenie została pomyślnie przesłana.  <br> <br> Jeśli z jakiegokolwiek powodu nie możesz wziąć udziału w wydarzeniu, anuluj je, korzystając z linku do anulowania w wiadomości e-mail lub SMS.",
      errorTitle: "Wystąpił błąd.",
      errorMessage: "Wystąpił błąd podczas rejestracji. ",
      bookNextEvent: "Zarezerwuj dalszą rejestrację",
      bookEventAgain: "Wróć"
    }
  },
  resources: {
    noResources: "Ten dostawca nie określił jeszcze żadnych zasobów.",
    step1: {
      title: "Zasoby",
      chooseResource: "Wybierz zasoby"
    },
    step2: {
      title: "Data",
      startDate: "Data rozpoczęcia: ",
      endDate: "Data końcowa: ",
      startTime: "Wybierz godzinę rozpoczęcia",
      endTime: "Wybierz godzinę zakończenia",
      resetCalendar: "Zresetuj kalendarz",
      noFreeResources: "Nie znaleziono zasobów, które można zarezerwować dla zakresu dat",
      selectDate: "Wybierz datę lub zakres dat"
    },
    step3: {
      title: "Zakończ",
      summary: "Podsumowanie",
      company: "Firma",
      store: "Lokalizacja",
      employee: "Pomoc",
      bookedEvent: "Wydarzenie",
      date: "Data",
      time: "Czas",
      price: "Cena",
      finalPrice: "Cena końcowa",
      total: "Suma",
      yourData: "Twoje dane",
      disclaimer: "Proszę podać swoje dane kontaktowe. ",
      prename: {
        label: "Imię",
        placeholder: "Imię",
        error: {
          minChars: "Imię wymaga co najmniej {{{ requiredLength }} znaków.",
          required: "Proszę podać imię."
        }
      },
      lastname: {
        label: "Nazwisko",
        placeholder: "Nazwisko",
        error: {
          minChars: "Nazwisko wymaga co najmniej {{{ requiredLength }} znaków.",
          required: "Wpisz nazwisko."
        }
      },
      phone: {
        label: "Telefon komórkowy",
        placeholder: "4179xxxxxxx",
        error: {
          pattern: "Numer telefonu może zawierać tylko cyfry.",
          minChars: "Numer telefonu wymaga co najmniej {{ requiredLength }} znaków.",
          required: "Wprowadź numer telefonu."
        }
      },
      eMail: {
        label: "E-mail",
        error: {
          pattern: "Proszę wpisać aktualny adres e-mail.",
          minChars: "Adres e-mail wymaga co najmniej {{ requiredLength }} znaków.",
          required: "Wprowadź adres e-mail."
        }
      },
      message: {
        label: "Wiadomość",
        placeholder: ""
      },
      customFields: {
        defaultOption: "Wybierz dowolną opcję",
        error: {
          minChars: "Too pole wymaga wprowadzenia min. {{ requiredLength }} znaków.",
          required: "Too pole jest obowiązkowe"
        }
      },
      cart: {
        emptyCartWarning: "Obecnie w koszyku nie ma żadnych zasobów."
      },
      From: "Z",
      To: "Do"
    },
    finalpage: {
      successTitle: "Pomyślnie zarezerwowałeś zasoby",
      successMessageDU: "Jeśli zmienisz zdanie, anuluj przynajmniej rezerwację zasobów {{ leadTime }} godziny przed ich zarezerwowaniem.",
      successMessageSIE: "Jeśli zmienisz zdanie, anuluj przynajmniej rezerwację zasobów {{ leadTime }} godziny przed ich zarezerwowaniem.",
      errorTitle: "Wystąpił błąd.",
      errorMessage: "Nie można zarezerwować zasobu. ",
      bookNextEvent: "Zarezerwuj dodatkowe zasoby",
      bookEventAgain: "Wróć"
    }
  },
  customer: {
    prename: {
      label: "Imię",
      placeholder: "Imię",
      error: {
        minChars: "Imię wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Proszę podaj swoje imię."
      }
    },
    lastname: {
      label: "Nazwisko",
      placeholder: "Nazwisko",
      error: {
        minChars: "Nazwisko wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Proszę podać swoje nazwisko."
      }
    },
    phone: {
      label: "Numer telefonu",
      placeholder: "4179......",
      error: {
        pattern: "Numer telefonu może zawierać tylko cyfry.",
        minChars: "Numer telefonu wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Wprowadź swój numer telefonu.",
        invalid: "Wprowadź prawidłowy numer telefonu komórkowego."
      }
    },
    eMail: {
      label: "E-mail",
      error: {
        pattern: "Proszę wpisać aktualny adres e-mail.",
        minChars: "Adres e-mail wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Adres e-mail jest wymagany."
      }
    },
    street: {
      label: "Ulica / Nr.",
      placeholder: "Nazwa ulicy",
      error: {
        minChars: "Ulica wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Proszę podać nazwę ulicy."
      }
    },
    zip: {
      label: "Kod pocztowy",
      placeholder: "Kod pocztowy",
      error: {
        minChars: "Kod pocztowy wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Wprowadź kod pocztowy.",
        pattern: "Proszę wprowadzić poprawny kod pocztowy.",
      }
    },
    city: {
      label: "Miasto",
      placeholder: "Nazwa miasta",
      error: {
        minChars: "Miasto wymaga co najmniej {{ requiredLength }} znaków.",
        required: "Wpisz nazwę swojego miasta."
      }
    },
    message: {
      label: "Wiadomość",
      placeholder: ""
    },
    customFields: {
      defaultOption: "Wybierz opcję",
      error: {
        minChars: "Too pole wymaga wprowadzenia min. {{ requiredLength }} znaków.",
        required: "Too pole jest wymagane."
      }
    },
    captcha: {
      required: "Too pole jest wymagane.",
      invalid: "Proszę wprowadzić poprawną captcha.",
      valid: "Captcha została pomyślnie dopasowana.",
      placeholder: "Wpisz tekst pokazany powyżej"
    }
  },
  stripe: {
    stripe_title: "Zapłać za kolejną wizytę online",
    submit_payment: "Potwierdź płatność",
    stripe_label: "Karta kredytowa lub karta debetowa",
    online_payment_optional: "W tym kroku masz możliwość umówienia się na kolejną wizytę już teraz.",
    online_payment_mandatory: "Aby dokończyć rezerwację, uprzejmie prosimy o opłacenie kolejnej wizyty już teraz."
  },
  alert_messages: {
    select_day_in_calendar_msg: "Wybierz konkretny dzień w kalendarzu, aby otrzymać sugerowane możliwe daty.",
    no_events_in_month: "Niestety w wybranym miesiącu nie ma już wolnych terminów.",
    no_events: "Niestety to wydarzenie nie ma okien, które można zarezerwować w przyszłości.",
    waitinglist_enabled_message: "Niektóre przedziały czasowe są już w pełni zarezerwowane.",
    waitinglist_booking_message: "Dziękujemy! ",
    window_is_fully_booked: "To okno czasowe jest w pełni zarezerwowane (w tym lista oczekujących).",
    waitinglist_is_available: "Ten przedział czasowy jest w pełni zarezerwowany, ale nadal jest <b>{{ number }}</b> wolne miejsca na liście oczekujących.",
    no_bookable_dates_message: "Niestety nie ma więcej dni do rezerwacji w miesiącu {{ month }}.",
    no_slots_dates_message: "Niestety nie ma już wolnych terminów w danym miesiącu {{ month }}.",
    no_slots_dates_reached_message: "Niestety nie ma już wolnych terminów w danym miesiącu {{ month }}.",
    no_slots_years_reached_message: "Niestety, nie ma więcej dostępnych terminów w roku {{ year }}. Maksymalny okres rezerwacji został osiągnięty.",
    no_bookable_dates_reached_message: "Niestety w danym miesiącu nie ma już wolnych terminów {{ month }}.",
    xss_error_message: "Ten tekst zawiera nieobsługiwane tagi HTML.",
    slot_already_reserved_by_other_error: "W międzyczasie termin, który próbujesz zarezerwować, został już zajęty przez kogoś innego. Wybierz inny termin i spróbuj ponownie później.",
    failed_to_reserve_slot_error: "Coś poszło nie tak podczas rezerwacji terminu. Spróbuj ponownie później!",
    reserved_by_someone: "Zarezerwowane przez kogoś innego"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: "Zrealizuj kupon",
    apply_coupon: "Zastosuj kupon",
    coupon: "Kupon",
    credit_or_debit_card: "karta kredytowa lub debetowa",
    invalid_coupon: "Nieważny kupon",
    applied_successfully: "Zastosowano pomyślnie"
  },
  tax_info: {
    taxes: "(w tym {{percentage}}% FAKTURA VAT)"
  },
  new_alert_component: {
    x_day_ago: "x dzień temu",
    x_days_ago: "x dni temu",
    x_minute_ago: "x minutę temu",
    x_minutes_ago: "x minut temu",
    x_hour_ago: "x godzinę temu",
    x_hours_ago: "x godzin temu",
    x_second_ago: "x sekundę temu",
    x_seconds_ago: "x sekund temu",
    x_week_ago: "x tydzień temu",
    x_weeks_ago: "x tygodni temu",
    x_month_ago: "x miesiąc temu",
    x_months_ago: "x miesięcy temu",
    x_year_ago: "x rok temu",
    x_years_ago: "x lat temu"
  },
  summary_page_translations: {
    paypal_warning_msg: "Zanim rezerwacja będzie możliwa, należy wpłacić otwartą kwotę za pomocą systemu PayPal.",
    paypal: "PayPal",
    saferpay_payment_overlay: "Ten ekran ukryje się automatycznie po zakończeniu lub anulowaniu płatności.<br/>Jeśli ekran płatności zamknął się przypadkowo, dotknij przycisku zamykania, aby go ukryć i ponownie zainicjować płatność.",
    or: "Lub",
    browser: "Przeglądać",
    choose_file: "Wybierz plik",
    clear: "Jasne",
    meeting_type: "Gdzie ma się odbyć spotkanie?",
    email: "E-mail",
    sms: "SMS-y",
    both_sms_email: "E-mail i SMS",
    notification_type: "Typ powiadomienia",
    payment_title: "Zapłata",
    payment_subtitle: "Zdecyduj, jak chcesz płacić za usługi",
    payment_subtitle_sie: "Zdecyduj, jak chcesz płacić za usługi",
    summary_title: "Podsumowanie",
    summary_subtitle: "Sprawdź swoje zamówienie",
    summary_subtitle_sie: "Sprawdź swoje zamówienie",
    payment_type: "Typ płatności",
    creditcard: "Karta kredytowa",
    invoice: "Faktura",
    lexoffice: "Faktura (LexOffice)",
    store: "Na miejscu",
    apply_coupon: "Chciałbym zastosować kod rabatowy",
    choose_payment_method: "Wybierz metodę płatności",
    tax: "w tym {{ rate }}% FAKTURA VAT",
    subtotal: "Suma częściowa",
    personal_form_title: "Dane osobiste",
    personal_form_subtitle: "Opowiedz nam coś o sobie",
    personal_form_subtitle_sie: "Opowiedz nam coś o sobie",
    inclusive: "włącznie",
    paymentMandatory: "Dostawca określił, że usługa musi być opłacona z góry. ",
    file_upload: "Udostępnianie pliku",
    file_upload_helper_text: "Można załączyć wiele plików",
    file_was_uploaded: "\"{{name}}\" został przesłany pomyślnie",
    max_file_size_5mb: "Przesłany plik jest za duży (dozwolone jest maksymalnie 5 MB).",
    malware_found: "Wystąpił problem podczas skanowania pliku pod kątem wirusów (Odmowa dostępu). Skontaktuj się z pomocą techniczną Calenso!",
    multiple_file_upload_helper_text: "Maksymalnie można przesłać 5 plików. ",
    single_file_upload_helper_text: "Można przesłać 1 plik. ",
    file_uploading: "Przesyłanie pliku jest nadal w toku. ",
    maximum_x_files: "Maksymalnie {{fileLength}} można przesyłać pliki.",
    document_invalid_file_type: "Nieprawidłowy rodzaj pliku. Dozwolone są tylko pliki PDF i Excel.",
    image_invalid_file_type: "Nieprawidłowy rodzaj pliku, proszę przesłać prawidłowy plik graficzny.",
    request_sms_code: "Kod zapytania",
    mobile_number_must_be_verified: "Numer musi zostać zweryfikowany.",
    verification_code: "Kod weryfikacyjny",
    sms_code_verification_failed_msg: "Wpisany kod niestety nie zgadza się z kodem, który wysłaliśmy SMS-em.",
    check: "Sprawdzać",
    phone_number_verified_successfully: "Numer został pomyślnie zweryfikowany.",
    resend_sms_helper_msg_1: "Nie otrzymałem kodu.",
    resend_sms_helper_msg_2: "Poproś o kod ponownie.",
    resend_sms_successfully: 'Kod SMS został wysłany pomyślnie',
    exceeded_sms_verification_limit_msg: "Ten numer przekroczył maksymalną liczbę kodów weryfikacyjnych. <a href=\"mailto:support@calenso.com\">support@calenso.com</a>).",
    salutation: "Pozdrowienie",
    mr: "Pan.",
    ms: "Pani.",
    other: "Inny",
    select_payment_method: "Proszę najpierw zapłacić otwartą kwotę za pomocą systemu PayPal, zanim będzie można złożyć rezerwację.",
    accept_data_policy: "Potwierdzam zapoznanie się z <a href=\"https://calenso.com/agb/\" target=\"_blank\">OWU Calenso</a> oraz <a href=\"https://calenso.com/datenschutz/\" target=\"_blank\">polityką ochrony danych osobowych</a> oraz wyrażam zgodę na przetwarzanie moich danych w celu rezerwacji terminu.",
    saferpay: "Bezpieczna płatność",
    saferpay_pay: "Dotknij, aby zapłacić za pomocą Saferpay",
    payment_success_title: "Pomyślna płatność",
    payment_success_message: "Opłacono pomyślnie {{ paymentMethod }}. ",
    title: "Tytuł",
    country: "Kraj",
    title_aria_describedby: "Specjalny tytuł wspierający Dr., Dr. med., Prof.",
    salutation_aria_describedby: "Forma grzecznościowa wspierająca Pana, Panią i inne",
    prename_aria_describedby: "Imię lub pierwsze imię",
    lastname_aria_describedby: "Nazwisko",
    notification_type_aria_describedby: "Aby zdefiniować w procesie rezerwacji, jak chcesz być powiadomiony o rezerwacji.",
    email_describedby: "E-mail",
    phoneNumber_aria_describedby: "Numer telefonu",
    smsPhoneCode_aria_describedby: "OTP do weryfikacji numeru telefonu",
    mobileNumber_aria_describedby: "Numer telefonu komórkowego",
    smsMobileCode_aria_describedby: "OTP do weryfikacji numeru telefonu komórkowego",
    street_aria_describedby: "Adres",
    zip_aria_describedby: "Kod pocztowy",
    city_aria_describedby: "Miasto",
    country_aria_describedby: "Kraj",
    comment_aria_describedby: "Dodatkowe uwagi, jeśli jakiekolwiek",
    internal_comment_aria_describedby: "Wewnętrzny komentarz (tylko do użytku wewnętrznego)",
    company_aria_describedby: "Nazwa firmy",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: "Tak, szukaj.",
    remove: "Usuń"
  },
  add: "Dodaj",
  compact: {
    no_service: 'Aktualnie nie można umówić żadnych wizyt, ponieważ nie znaleziono pasującej usługi.',
    navigation: {
      date: 'Daty',
      date_slots: 'Daty i terminy',
      personal_info: 'Dane osobowe'
    }
  }
};
