import { Component, inject, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { AttributesModel } from '../../../../db-models/user-data-dao';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';

@Component({
  selector: 'app-cw-attribute-tooptip',
  standalone: true,
  imports: [NgbTooltip, FontAwesomeModule, TranslateModule, TranslationPipe],
  templateUrl: './cw-attribute-tooptip.component.html',
  styleUrl: './cw-attribute-tooptip.component.scss'
})
export class CwAttributeTooptipComponent {
  @Input() attributes: AttributesModel[];
  @Input() id: string;
  @Input() lang: string;

  private translate = inject(TranslateService);
  protected readonly baseUrl = environment.baseUrl;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }
}
