<div class="info-icon">
  <span placement="left"
    #tooltip="ngbTooltip"
    [ngbTooltip]="attributeTooltip"
    [autoClose]="false"
    id="{{ id }}"
  >
    <fa-icon class="icon" icon="circle-info"></fa-icon>
  </span>
</div>

<ng-template #attributeTooltip>
  @if (attributes?.length) {
    @for (attribute of attributes; track attribute.uuid) {
      @if (attribute.type === 'select') {
        <div class="attribute-container cbw-attribute-container">
          <div class="cbw-attribute">
            {{ attribute | translation: 'name': lang }}:
          </div>
          <div class="attribute-value-section">
            @for (value of attribute.attributes_values; track value.uuid; let last = $last) {
              <div class="attribute-value-img">
                @if (value.icon_url !== null) {
                  <img
                    #image
                    class="cbw-attribute-icon"
                    alt="{{ value.value }}"
                    src="{{ value.icon_url }}"
                    (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                  />
                }
                <div class="cbw-attribute-value">
                  {{ value | translation: 'value' : lang }}
                  @if (!last) { | }
                </div>
              </div>
            }
          </div>
        </div>
      } @else {
        <div class="cbw-attribute cbw-attribute-container">
          {{ attribute | translation: 'name': lang }}:
          @if (attribute.attributes_values?.length) {
            {{ +attribute.attributes_values[0].value === 1 ? ( 'yes' | translate ): ( 'no' | translate ) }}
          }
        </div>
      }
    }
  }
</ng-template>